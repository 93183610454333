import React from 'react';
import PropTypes from 'prop-types';

import './IconText.scss';

function IconText({ icon, text, theme }) {
    let mod_class = ['IconText', theme !== undefined ? 'theme-' + theme : ''];
    mod_class = mod_class.join(' ');

    return (
        <div className={mod_class}>
            <div className="IconText--item IconText--icon">{icon}</div>
            <div className="IconText--item IconText--text">{text}</div>
        </div>
    );
}

IconText.defaultProps = {
    theme: undefined, // success, error
};

export default IconText;
