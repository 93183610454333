import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { any } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import Button from '@atoms/Button/Button';
import Divider from '@atoms/Divider/Divider';
import Module from '@atoms/Module/Module';
import Section from '@atoms/Section/Section';
import Title from '@atoms/Title/Title';
import { GlobalContext } from '@context/GlobalContext';
import './Modal.scss';

function Modal(props) {
    const mod_class = ['Modal'];
    const { t } = useTranslation();
    const [setGbContext] = useContext(GlobalContext);
    const [showDialog, setShowDialog] = useState(props.isOpen);

    useEffect(() => {
        setShowDialog(props.isOpen);
    }, [props.isOpen]);

    function closeModal() {
        if (setGbContext && typeof setGbContext === 'function') {
            setGbContext((prevState) => ({
                ...prevState,
                modalIsOpen: false,
                privacyModalIsOpen: false,
                insuranceModalIsOpen: false,
                howDoesItWorkModalIsOpen: false,
                newsletterModalIsOpen: false,
                landboatModalIsOpen: false,
                costOfTherapyModal: false,
                miniInsuranceModal: false,
                paymentInfoModalIsOpen: false,
                whyAuswahlModalIsOpen: false,
                whyReferralInviteModalIsOpen: false,
                prepareInfoCallModalIsOpen: false,
                whatIsCoachingModalIsOpen: false,
                limitationOfCoachingModalIsOpen: false,
                promoteVoucherModalIsOpen: false,
            }));
        }

        setShowDialog(false);
        props.onClose && props.onClose();
    }

    return (
        <Dialog
            isOpen={showDialog}
            onDismiss={closeModal}
            aria-label="Modal"
            className={mod_class}
        >
            <Section container="short">
                <Module>
                    <div className="Modal--header">
                        <div className="g_clearfix">
                            <div className="g_3_4">
                                <Title size="ml">{props.title}</Title>
                            </div>

                            <div className="Modal--closeBtn">
                                <Button
                                    onClick={() => closeModal()}
                                    label={<CgClose />}
                                    variant="naked"
                                    theme="highlighted"
                                />
                            </div>
                        </div>
                        <Divider spacing="xs" />
                    </div>
                    <div className="Modal--body">{props.children}</div>
                    <div className="Modal--footer">
                        <Divider spacing="xs" />
                        {props.specialFooter ? (
                            props.specialFooter
                        ) : (
                            <div className="g_center">
                                <Button
                                    label={t('button.back')}
                                    onClick={() => closeModal()}
                                />
                            </div>
                        )}
                    </div>
                </Module>
            </Section>
        </Dialog>
    );
}

Modal.propTypes = {
    children: any,
};

export default Modal;
