import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { AiOutlineCheck } from 'react-icons/ai';
import Module from '@atoms/Module/Module';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import IconText from '../IconText/IconText';
import Button from '@atoms/Button/Button';
import './CoverageQualityShortInfo.scss';
import { useLocale } from '@hooks';

const CoverageQualityShortInfo = () => {
    const { t } = useTranslation();
    const { getAppLinkWithCurrentLang } = useLocale();
    return (
        <div className="CoverageQualityShortInfo">
            <div className="CoverageQualityShortInfo-leftContent">
                <div className="CoverageQualityShortInfo--card">
                    <Text size="m" align="center">
                        {t('coverage.service.comparison.other.way.title')}
                    </Text>
                    <Title size="l" align="center">
                        {t('coverage.service.comparison.other.way.subtitle')}
                    </Title>
                    <Section spacingTop="xs" container="large">
                        <ul>
                            <li>
                                <Text size="s">
                                    {t(
                                        'coverage.service.comparison.other.way.point.one'
                                    )}
                                </Text>
                            </li>
                            <li>
                                <Text size="s">
                                    {t(
                                        'coverage.service.comparison.other.way.point.two'
                                    )}
                                </Text>
                            </li>
                            <li>
                                <Text size="s">
                                    {t(
                                        'coverage.service.comparison.other.way.point.three'
                                    )}
                                </Text>
                            </li>
                            <li>
                                <Text size="s">
                                    {t(
                                        'coverage.service.comparison.other.way.point.four'
                                    )}
                                </Text>
                            </li>
                            <li>
                                <Text size="s">
                                    {t(
                                        'coverage.service.comparison.other.way.point.five'
                                    )}
                                </Text>
                            </li>
                        </ul>
                    </Section>
                </div>
            </div>

            <div className="CoverageQualityShortInfo-rightContent">
                <Module radius="giant" padding="l">
                    <Section spacingBottom="s">
                        <Text size="m" align="center">
                            {t('coverage.service.comparison.aepsy.way.title')}
                        </Text>
                        <Title size="xl" align="center">
                            {t(
                                'coverage.service.comparison.aepsy.way.subtitle'
                            )}
                        </Title>
                        <Text size="s" align="center">
                            {t('coverage.service.comparison.aepsy.way.text')}
                        </Text>
                    </Section>

                    <div className="CoverageQualityShortInfo--list">
                        <div className="CoverageQualityShortInfo--inner">
                            {[
                                {
                                    text: t(
                                        'coverage.service.comparison.aepsy.way.point.one'
                                    ),
                                    icon: <AiOutlineCheck />,
                                },
                                {
                                    text: t(
                                        'coverage.service.comparison.aepsy.way.point.two'
                                    ),
                                    icon: <AiOutlineCheck />,
                                },
                                {
                                    text: t(
                                        'coverage.service.comparison.aepsy.way.point.three'
                                    ),
                                    icon: <AiOutlineCheck />,
                                },
                                {
                                    text: t(
                                        'coverage.service.comparison.aepsy.way.point.four'
                                    ),
                                    icon: <AiOutlineCheck />,
                                },
                                {
                                    text: t(
                                        'coverage.service.comparison.aepsy.way.point.five'
                                    ),
                                    icon: <AiOutlineCheck />,
                                },
                                {
                                    text: t(
                                        'coverage.service.comparison.aepsy.way.point.six'
                                    ),
                                    icon: <AiOutlineCheck />,
                                },
                                {
                                    text: t(
                                        'coverage.service.comparison.aepsy.way.point.seven'
                                    ),
                                    icon: <AiOutlineCheck />,
                                },
                                {
                                    text: t(
                                        'coverage.service.comparison.aepsy.way.point.eight'
                                    ),
                                    icon: <AiOutlineCheck />,
                                },
                                {
                                    text: t(
                                        'coverage.service.comparison.aepsy.way.point.nine'
                                    ),
                                    icon: <AiOutlineCheck />,
                                },
                            ].map((item: any) => (
                                <IconText
                                    icon={
                                        <Title
                                            size="ml"
                                            tag="span"
                                            theme="light"
                                        >
                                            {item.icon}
                                        </Title>
                                    }
                                    text={
                                        <Title size="s" noMargin tag="span">
                                            {item.text}
                                        </Title>
                                    }
                                />
                            ))}
                        </div>
                    </div>
                    <Section spacingTop="s">
                        <a
                            href={getAppLinkWithCurrentLang(
                                process.env.GATSBY_START_FULL_FUNNEL_URL
                            )}
                        >
                            <Button
                                variant="naked"
                                iconArrow
                                label={t('button.try.now')}
                                align="center"
                            />
                        </a>
                    </Section>
                </Module>
            </div>
        </div>
    );
};

export default CoverageQualityShortInfo;
