import React from 'react';
import Title from '@atoms/Title/Title';
import Text from '@atoms/Text/Text';
import './SimpleCard.scss';

interface Props {
    title: string;
    subtitle?: string;
    children?: React.ReactNode;
    border?: 's' | 'm' | 'l' | 'giant';
    padding?: 's' | 'm' | 'l' | 'xl';
    theme?: 'default' | 'purple' | 'pink-gradient';
}

const SimpleCard = ({ title, subtitle, children, border, padding, theme }) => {
    return (
        <div
            className={`SimpleCard border-${border} padding-${padding} theme-${theme}`}
        >
            <Title size="ml">{title}</Title>
            {subtitle && <Text size="m">{subtitle}</Text>}
            {children && children}
        </div>
    );
};

export default SimpleCard;
