import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useRef } from 'react';
import Button from '@atoms/Button/Button';
import Title from '@atoms/Title/Title';
import './VideoBackgroundWithContent.scss';
import Link from '@components/Link';
import { useLocale } from '@hooks';
import { useIsMobile } from '../../hooks/useIsDevice';

interface Props {
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    cta?: React.ReactNode;
}

const VideoBackgroundWithContent: React.FC<Props> = ({
    title,
    subtitle,
    cta,
}) => {
    const videoRef = useRef(null);

    const { t } = useTranslation();
    const { getAppLinkWithCurrentLang } = useLocale();

    const isMobile = useIsMobile();
    
    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.load();
      }
    }, [isMobile]);

    return (
        <div className="VideoBackgroundWithContent">
            <div className="green-gradient-background" />
            <video playsInline autoPlay muted loop ref={videoRef}>
                <source
                    src={isMobile ? "/video/01_single_person_mobile.mp4" : "/video/01_single_person_desktop.mp4"}
                    type="video/mp4"
                />
            </video>
            <div className="VideoBackgroundWithContent--inner">
                {title ? (
                    title
                ) : (
                    <Title size="xl" theme="white" align="center" font="alt">
                        <Trans
                            i18nKey={'footer.cta.banner.title'}
                            components={{ br: <br /> }}
                        />
                    </Title>
                )}
                {subtitle && subtitle}
                {cta ? (
                    cta
                ) : (
                    <div>
                        <a
                            href={getAppLinkWithCurrentLang(
                                process.env.GATSBY_START_FULL_FUNNEL_URL
                            )}
                        >
                            <Button
                                theme="white"
                                align="center"
                                label={t('find.pyschologist.cta.label')}
                                isMobileFullsize
                            />
                        </a>
                        <Link to="/for-therapists/">
                            <Button
                                theme="white"
                                variant="naked"
                                align="center"
                                label={t('nav.label.for.psychologist')}
                                iconArrow
                                isMobileFullsize
                            />
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoBackgroundWithContent;
