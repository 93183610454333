import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import Button from '@atoms/Button/Button';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import TitleGroup from '@atoms/TitleGroup/TitleGroup';
import Footer from '@components/Footer/Footer';
// Modules
import { Box } from '@material-ui/core';
import { BsFileEarmarkBreak } from 'react-icons/bs';
import { CgCreditCard } from 'react-icons/cg';
import { MdTimelapse } from 'react-icons/md';
import MarkdownText from '@atoms/MarkdownText/MarkdownText';
import Module from '@atoms/Module/Module';
import CoveragePricingShortInfo from '@components/CoveragePricingShortInfo';
import CoverageQualityShortInfo from '@components/CoverageQualityShortInfo';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import InsuranceChecker from '@components/insurance-checker';
import KeyFeaturesSlider from '@components/KeyFeaturesSlider';
import Layout from '@components/layout';
import Modal from '@components/Modal/Modal';
import Seo from '@components/seo';
import SingleAccordion from '@components/SingleAccordion/SingleAccordion';
import Stepper from '@components/Stepper';
import VideoBackgroundWithContent from '@components/VideoBackgroundWithContent';
import { useLocale } from '@hooks';
import './Coverage.scss';
import BudgetingToolNudge from '@components/BudgetingToolNudge/BudgetingToolNudge';
import { FaHandHoldingHeart } from 'react-icons/fa';
import { withLocale } from '@hocs';

export const query = graphql`
    fragment FaqField on AEPSYCMS_Faq {
        qar(pagination: { limit: 99 }) {
            question
            answer
        }
    }
    query($language: String!, $customLangKey: AEPSYCMS_I18NLocaleCode!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        pricingCardImage: file(
            relativePath: { eq: "approach/card-green-background-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        pricingCardMobileImage: file(
            relativePath: { eq: "approach/card-green-background-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aepsyCms {
            faqLang: faqs(
                locale: $customLangKey
                filters: { key: { eq: "COVERAGE_PAGE" } }
            ) {
                data {
                    attributes {
                        ...FaqField
                    }
                }
            }
        }
    }
`;

const HOW_MANY_SESSION_CONTENT = `Bei **leichteren Belastungen oder einfachen Fragestellungen können wenige Sitzungen bereits Wirkung zeigen.** Bei komplexeren Herausforderungen können Therapien über mehrere Wochen oder Monate Hilfe bieten.

Das Erstgespräch mit Therapeut:innen hilft jeweils, um herauszufinden, was das gemeinsame Ziel ist und in was für einem Rhythmus / Zeitrahmen dieses erreicht werden sollte. 

Du kannst also kostenlos ein Gefühl dafür entwickeln und / oder nach den ersten eins bis zwei Sitzungen entscheiden, wie du weiterfahren möchtest.  

Zudem hast du bei Aepsy jederzeit die Möglichkeit deine Sitzungen zu unterbrechen, die Psycholog:in zu wechseln oder uns Rückmeldungen zu deinen Erfahrungen (positiv und negativ) zu geben. 
`;

const BASE_INSURANCE_CONTENT = `
Beim Angebot von Aepsy steht die Beziehung zwischen dir und den Psycholog:innen im Fokus. Es handelt sich primär um psychologische Coachings und Psychotherapien, die keinen Krankheitswert voraussetzen. 

Wir richten unser Angebot darum an Selbstzahler:innen (& Zusatzversicherungen) aus, da so in der Grundversorgung mehr Platz für Andere bleibt. Falls für dich also einzig eine Deckung über die Grundversicherung in Frage kommt, bieten dir andere Stellen potenziell ein besseres Sucherlebnis.
`;

const CoveragePage: React.FC<any> = ({ location, data }) => {
    const { t } = useTranslation();

    const { getAppLinkWithCurrentLang } = useLocale();
    const [modalBaseInsuranceShown, setModalBaseInsuranceShown] = useState(
        false
    );

    const [howManySessionShown, setHowManySessionShown] = useState(false);
    const faq = data.aepsyCms.faqLang.data[0].attributes.qar;
    const KEY_USP = [
        {
            title: t('coverage.key.usp.full.control.title'),
            subtitle: t('coverage.key.usp.full.control.subtitle'),
            icon: <FaHandHoldingHeart />,
        },
        {
            title: t('coverage.key.usp.your.rythm.title'),
            subtitle: t('coverage.key.usp.your.rythm.subtitle'),
            icon: <MdTimelapse />,
        },
        {
            title: t('coverage.key.usp.full.transparency.title'),
            subtitle: t('coverage.key.usp.full.transparency.subtitle'),
            icon: <CgCreditCard />,
        },
        {
            title: t('coverage.key.usp.no.diagnosis.title'),
            subtitle: t('coverage.key.usp.no.diagnosis.subtitle'),
            icon: <BsFileEarmarkBreak />,
        },
    ];

    const showBaseInsuranceModal = () => setModalBaseInsuranceShown(true);

    // const showHowManySessionModal = () => setHowManySessionShown(true);
    const { pricingCardImage, pricingCardMobileImage } = data;
    return (
        <Layout>
            <Seo
                title={t('coverage.seo.title')}
                description={t('coverage.seo.description')}
            />
            <div className="CoveragePage">
                <div className="Header--wrapper">
                    <div className="green-gradient-background"></div>

                    <div className="Header--VideoWrapper">
                        <HeaderNav location={location.pathname} theme="white" />
                        <div className="CoverageHeader--bg" />
                        <div className="Header--title">
                            <Title
                                tag="h1"
                                font="alt"
                                size="xxl"
                                theme="white"
                                align="center"
                            >
                                {t('coverage.hero.header.title')}
                            </Title>
                            <Title
                                tag="h2"
                                theme="white"
                                size="s"
                                align="center"
                                noMargin
                            >
                                {t('coverage.hero.header.subtitle')}
                            </Title>

                            <Section spacingTop="xs">
                                <AnchorLink
                                    offset={() => 120}
                                    href="#learn-more"
                                >
                                    <Button
                                        theme="white"
                                        label={t('learn.more')}
                                        align="center"
                                        isMobileFullsize
                                    />
                                </AnchorLink>
                            </Section>
                        </div>
                    </div>
                </div>
                <div className="global_theme-green">
                    <Section theme="dark">
                        <div id="learn-more">
                            <KeyFeaturesSlider list={KEY_USP} />
                        </div>
                    </Section>
                    <Section
                        theme="dark"
                        container="large"
                        spacingTop="m"
                        spacingBottom="l"
                    >
                        <Title tag="p" size="xl" theme="white">
                            <Trans
                                i18nKey={'coverage.key.statement.quote'}
                                components={{ br: <br /> }}
                            />
                        </Title>
                    </Section>
                    <Section container="large" spacingBottom="m">
                        <div className="CoverageQualityShortInfo--wrapper">
                            <CoverageQualityShortInfo />
                        </div>
                    </Section>

                    <CoveragePricingShortInfo
                        imageUrlSharp={pricingCardImage}
                        mobileImageUrlSharp={pricingCardMobileImage}
                    />

                    <Section container="large" spacingBottom="m">
                        {/* <Button
                            variant="naked"
                            label={t(
                                'coverage.how.many.session.modal.button.label'
                            )}
                            align="center"
                            onClick={showHowManySessionModal}
                        /> */}
                        <BudgetingToolNudge />
                    </Section>

                    <Section container="short" spacingBottom="m">
                        <TitleGroup
                            title={t('coverage.insurance.info.section.title')}
                            subtitle={t(
                                'coverage.insurance.info.section.subtitle'
                            )}
                            align="desktopCenter"
                        />
                    </Section>

                    <Stepper
                        list={[
                            {
                                icon: '🤝',
                                title: t(
                                    'coverage.insurance.info.how.to.step.one.title'
                                ),
                                subtitle: t(
                                    'coverage.insurance.info.how.to.step.one.subtitle'
                                ),
                                buttonLabel: t('button.get.started'),
                                url: getAppLinkWithCurrentLang(
                                    process.env.GATSBY_START_FULL_FUNNEL_URL
                                ),
                            },
                            {
                                icon: '📞',
                                title: t(
                                    'coverage.insurance.info.how.to.step.two.title'
                                ),
                                subtitle: t(
                                    'coverage.insurance.info.how.to.step.two.subtitle'
                                ),
                            },
                            {
                                icon: '👀',
                                title: t(
                                    'coverage.insurance.info.how.to.step.three.title'
                                ),
                                subtitle: t(
                                    'coverage.insurance.info.how.to.step.three.subtitle'
                                ),
                            },
                            {
                                icon: '🗓',
                                title: t(
                                    'coverage.insurance.info.how.to.step.four.title'
                                ),
                                subtitle: t(
                                    'coverage.insurance.info.how.to.step.four.subtitle'
                                ),
                            },
                            {
                                icon: '💌',
                                title: t(
                                    'coverage.insurance.info.how.to.step.five.title'
                                ),
                                subtitle: t(
                                    'coverage.insurance.info.how.to.step.five.subtitle'
                                ),
                            },
                            {
                                icon: '💰',
                                title: t(
                                    'coverage.insurance.info.how.to.step.six.title'
                                ),
                                subtitle: t(
                                    'coverage.insurance.info.how.to.step.six.subtitle'
                                ),
                            },
                        ]}
                    />

                    <Section container="short" spacingTop="xs">
                        <div className="g_center g_2_3">
                            <Text align="center" size="xxs">
                                {t(
                                    'coverage.insurance.info.special.information.text'
                                )}
                            </Text>
                        </div>
                    </Section>

                    <Section container="short" spacingTop="m">
                        <Module padding="l" radius="xl" highlighted>
                            <InsuranceChecker />
                        </Module>

                        <Section spacingTop="m" spacingBottom="s">
                            <Box
                                display="flex"
                                justifyContent="center"
                                sx={{ width: '100%' }}
                            >
                                <Box display="flex" alignItems="center">
                                    <Text theme="light" noMargin>
                                        <IoMdInformationCircleOutline size="32" />
                                    </Text>
                                    <Box sx={{ ml: 2 }}>
                                        <Title size="s" noMargin>
                                            {t(
                                                'coverage.insurance.info.base.not.supported.title'
                                            )}
                                        </Title>
                                        <Button
                                            theme="highlighted"
                                            variant="inline"
                                            label={t('learn.more')}
                                            onClick={showBaseInsuranceModal}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Section>
                        <a
                            href={getAppLinkWithCurrentLang(
                                process.env.GATSBY_START_FULL_FUNNEL_URL
                            )}
                        >
                            <Button
                                label={t('find.pyschologist.cta.label')}
                                align="center"
                                isMobileFullsize
                            />
                        </a>
                    </Section>

                    <Section container="short" spacingTop="m">
                        <TitleGroup
                            title={t('faq.section.title')}
                            subtitle={t('faq.section.subtitle')}
                            align="desktopCenter"
                        />
                        <Section spacing="s">
                            <SingleAccordion data={faq} comingFrom="buchen" />
                        </Section>
                    </Section>
                    <Section container="large" spacingBottom="m" spacingTop="s">
                        <VideoBackgroundWithContent />
                    </Section>
                    {/* Footer */}
                    <Footer />
                </div>
            </div>
            {modalBaseInsuranceShown && (
                <Modal
                    isOpen={modalBaseInsuranceShown}
                    title={t(
                        'coverage.insurance.info.base.not.supported.title'
                    )}
                    onClose={() => setModalBaseInsuranceShown(false)}
                >
                    <MarkdownText content={BASE_INSURANCE_CONTENT} />
                </Modal>
            )}
            {howManySessionShown && (
                <Modal
                    isOpen={howManySessionShown}
                    title={t('coverage.how.many.session.modal.button.label')}
                    onClose={() => setHowManySessionShown(false)}
                >
                    <MarkdownText content={HOW_MANY_SESSION_CONTENT} />
                </Modal>
            )}
        </Layout>
    );
};

export default withLocale(CoveragePage);
