import React from 'react';
import Module from '@atoms/Module/Module';
import Title from '@atoms/Title/Title';
import Text from '@atoms/Text/Text';
import Section from '@atoms/Section/Section';
import { BsArrowsAngleExpand } from 'react-icons/bs';
import './CardWithIcon.scss';
import { Box } from '@material-ui/core';
import Icon from '@atoms/Icon/Icon';

interface Props {
    icon: React.ReactNode;
    title: string;
    subtitle?: string;
    clickable?: boolean;
}

const CardWithIcon: React.FC<Props> = ({
    icon,
    title,
    subtitle,
    clickable = false,
}) => {
    const mod_class = ['CardWithIcon', clickable ? 'is-clickable' : ' ']
        .join(' ')
        .replace(/\s{2,}/g, ' ');

    return (
        <Module padding="m" radius="giant" className={mod_class}>
            <Box display="flex" justifyContent="space-between">
                <Section spacingBottom="xs">
                    {icon && <Icon theme="green-3" icon={icon} size="m" />}
                </Section>
                {clickable && (
                    <div className="CardWithIcon--expandIcon">
                        <BsArrowsAngleExpand />
                    </div>
                )}
            </Box>

            <Title tag="h4" size="s" noMargin>
                {title}
            </Title>
            {subtitle && <Text size="s">{subtitle}</Text>}
        </Module>
    );
};

export default CardWithIcon;
