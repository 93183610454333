import React from 'react';
import CardSlider from '../CardSlider/CardSlider';
import { SwiperSlide } from 'swiper/react';
import Button from '@atoms/Button/Button';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import Avatar from '../Avatar/Avatar';
import './Stepper.scss';
import Section from '@atoms/Section/Section';
interface Props {
    list: any[];
}

const Stepper: React.FC<Props> = ({ list }) => {
    return (
        <div className="Stepper">
            <CardSlider
                sliderOption={{
                    initialSlide: 0,
                    slidesPerView: 1.3,
                    breakpoints: {
                        601: {
                            slidesPerView: 2.3,
                            spaceBetween: 16,
                        },
                        901: {
                            slidesPerView: 3.3,
                        },
                        1101: {
                            slidesPerView: 4.3,
                        },
                    },
                }}
            >
                {list.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                        <div className="Stepper--item">
                            {index !== list.length - 1 && (
                                <div className="Stepper--bg"></div>
                            )}
                            {item.icon && (
                                <Section spacingBottom="xs">
                                    <Avatar icon={item.icon} size="xs" />
                                </Section>
                            )}
                            {item.title && (
                                <Title size="s" noMargin>
                                    {item.title}
                                </Title>
                            )}
                            {item.subtitle && (
                                <Text size="s" align="center">
                                    {item.subtitle}
                                </Text>
                            )}
                            {item.buttonLabel && (
                                <a href={item.url}>
                                    <Button
                                        variant="naked"
                                        label={item.buttonLabel}
                                    />
                                </a>
                            )}
                        </div>
                    </SwiperSlide>
                ))}
            </CardSlider>
        </div>
    );
};

export default Stepper;
