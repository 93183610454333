import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleCard from '@components/Card/SimpleCard';
import Title from '@atoms/Title/Title';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';
import { useLocale } from '@hooks';
import { Box } from '@material-ui/core';

const BudgetingToolNudge = () => {
    const { t } = useTranslation();
    const { getAppLinkWithCurrentLang } = useLocale();

    return (
        <SimpleCard border="giant" theme="pink-gradient" padding="xl">
            <Title font="alt" size="xl" align="center" theme="white">
                {t('budgeting.tool.nudge.title')}
            </Title>
            <Box display="flex" justifyContent="center">
                <Box sx={{ maxWidth: '500px' }}>
                    <Text size="m" align="center" theme="white">
                        {t('budgeting.tool.nudge.subtitle')}
                    </Text>
                </Box>
            </Box>

            <Section spacingTop="xs">
                <a
                    href={getAppLinkWithCurrentLang(
                        process.env.GATSBY_START_BUDGETING_TOOL_URL
                    )}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button
                        label={t('budgeting.tool.nudge.cta')}
                        align="center"
                        theme="white"
                    />
                </a>
            </Section>
        </SimpleCard>
    );
};

export default BudgetingToolNudge;
