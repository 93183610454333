import React from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import Module from '@atoms/Module/Module';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import IconText from '../IconText/IconText';
import Button from '@atoms/Button/Button';
import './CoveragePricingShortInfo.scss';
import CardWithBackground from '../Card/CardWithBackground';
import { useLocale } from '@hooks';

const CoveragePricingShortInfo = ({ queryParam, imageUrlSharp, mobileImageUrlSharp }) => {
    const { t } = useTranslation();
    const { getAppLinkWithCurrentLang } = useLocale();

    return (
        <div className="CoveragePricingShortInfo">
            <div className="CoveragePricingShortInfo--leftContent">
                <CardWithBackground
                    urlSharp={imageUrlSharp}
                    mobileSharpImage={mobileImageUrlSharp}
                    greenBackground
                >
                    <Title tag="span" size="xl" font="alt" theme="white">
                        {t('coverage.pricing.section.title')}
                    </Title>
                </CardWithBackground>
            </div>
            <div className="CoveragePricingShortInfo--rightContent">
                <div className="CoveragePricingShortInfo--card">
                    <Module radius="giant" padding="l">
                        <Section spacingBottom="s">
                            <Title
                                tag="h3"
                                size="l"
                                align="center"
                                noMargin
                                font="alt"
                            >
                                {t('coverage.pricing.card.title')}
                            </Title>
                            <Text size="l" align="center">
                                <Trans
                                    i18nKey={'coverage.pricing.card.subtitle'}
                                    components={{ br: <br /> }}
                                />
                            </Text>
                        </Section>

                        <Section container="short">
                            {[
                                t('coverage.pricing.card.key.point.one'),
                                t('coverage.pricing.card.key.point.two'),
                                t('coverage.pricing.card.key.point.three'),
                                t('coverage.pricing.card.key.point.four'),
                                t('coverage.pricing.card.key.point.five'),
                            ].map((item: any) => (
                                <Section spacingBottom="xs">
                                    <IconText
                                        icon={
                                            <Title
                                                size="l"
                                                tag="span"
                                                noMargin
                                                theme="light"
                                            >
                                                <AiOutlineCheckCircle />
                                            </Title>
                                        }
                                        text={
                                            <Title size="s" noMargin tag="span">
                                                {item}
                                            </Title>
                                        }
                                    />
                                </Section>
                            ))}
                        </Section>
                        <a
                            href={`${getAppLinkWithCurrentLang(
                                process.env.GATSBY_START_FULL_FUNNEL_URL
                            )}/${queryParam || ''}`}
                        >
                            <Button
                                variant="naked"
                                iconArrow
                                label={t('button.get.started')}
                                align="center"
                            />
                        </a>
                    </Module>
                </div>
            </div>
        </div>
    );
};

export default CoveragePricingShortInfo;
